import React, { useEffect, useState } from "react";
import { getStaffWorkSchedules } from "../../redux/actions/workScheduleActions";
import "./WorkSchedule.scss"
import 'bootstrap/dist/css/bootstrap.min.css';

import { Tooltip, OverlayTrigger } from 'react-bootstrap';





const WorkScheduleComponent = () => {

    const [month, setMonth] = useState(new Date());
    const [monthDates, setMonthDates] = useState([]);
    const [monthDays, setMonthDays] = useState([]);
    const [tableData, setTableData] = useState([])

    const renderTooltip = (props, content) => (
        <Tooltip id="custom-tooltip" {...props}>
            <div className="main">
                {/* <h3>Schedule Time</h3>
                <p>{content.hours || '07:00 to 16:00'}</p> */}

                <h3>Report Time</h3>
                <p>{content.hours || '07:00 to 16:00'}</p>

                <h3>Personal</h3>
                <p>{content.user || 'Person, William'}</p>

                <h3>Customer</h3>
                <p>{content.customer || 'ABC'}</p>

                <h3>Work Order</h3>
                <p>{content.workOrder || '000000000'}</p>

                <h3>Information</h3>
                <p>{content.info || 'Additional details...'}</p>

                <h3>Personal Note</h3>
                <p>{content.note || 'Notes here...'}</p>
            </div>
        </Tooltip>
    );

    function getDatesAndDays(year, month) {
        const dates = [];
        const days = [];
        const totalDays = new Date(year, month, 0).getDate();
        for (let day = 1; day <= totalDays; day++) {
            const date = new Date(year, month - 1, day);
            const formattedDate = day.toString().padStart(2, "0");
            const formattedDay = date.toLocaleString("en-US", { weekday: "short" }).toUpperCase().slice(0, 2);
            
            dates.push(formattedDate);
            days.push(formattedDay);
        }
        console.log("dates", dates)
        setMonthDates(dates)
        setMonthDays(days)
    }

    const getPreviousMonthDate = () => {
        const year = month.getFullYear();
        const mon = month.getMonth();
        const previousMonthDate = new Date(year, mon - 1, 1);
        setMonth(previousMonthDate);
    };

    const getNextMonthDate = () => {
        const dateObj = month;
        const year = dateObj.getFullYear();
        const mon = dateObj.getMonth() + 1;
        const nextMonthDate = new Date(year, mon, 1);
        setMonth(nextMonthDate);
    }

    const handleGetStaffWorkSchedules = async () => {
        const data = await getStaffWorkSchedules({ month: month.getMonth() + 1, year: month.getFullYear() })
        setTableData(data.data)
    }

    const assignColor = (hours) => {
        const time = hours.split(":")[0];
       return +time === 0 ? "" : +time === 8 ? "bg-green" : +time > 8 && "bg-blue"
    }

    useEffect(() => {
        handleGetStaffWorkSchedules()
        getDatesAndDays(month.getFullYear(), month.getMonth() + 1)
    }, [])
    
    useEffect(() => {
        handleGetStaffWorkSchedules()
        getDatesAndDays(month.getFullYear(), month.getMonth() + 1)
    },[month])

    return (
        <>
           
            <table className="schedule ">
                <tr className="heading-name">
                    <th className="heading-1 text-center">Month</th>
                    <th colspan="29" className="heading-1 text-center">
                        <div style={{display:"flex",justifyContent:"center",gap:"5px"}}>
                        <div className="cursor-pointer" onClick={getPreviousMonthDate}>{`<< `}</div>
                        {`${month.getMonth() + 1} ${month.getFullYear()}`}
                        <div className="cursor-pointer" onClick={getNextMonthDate}>{` >>`}</div>
                        </div>
                    </th>
                </tr>
                <tr className="week-date">
                    <td rowspan="2" className="heading-1 ">Day</td>
                    {monthDates.map((d) => (
                        <td className="heading-date">{d}</td>
                    ))}
                </tr>
                <tr className="week-day">
                    {monthDays.map((d) => (
                        <td className={`heading-date ${d === "SU" && "custom-red"}`}>{d}</td>
                    ))}
                </tr>
                {tableData?.map((e) => (
                    <tr>
                    <td className="heading">{e.userDetails.email}</td>
                    {e.dates.map((h) => (
                        <OverlayTrigger
                        placement="bottom"
                        overlay={(props) =>
                            renderTooltip(props, {
                                hours: h.totalHours,
                                user: e.userDetails.name,
                                workOrder: e.aoname,
                                customer: e.customerName
                            })
                        }
                    >
                        <td className={`table-light ${assignColor(h.totalHours)}`}>{h.totalHours}</td>
                        </OverlayTrigger>
                    ))}
                </tr>
                ))}

            </table>

        </>
    )
}

export default WorkScheduleComponent;