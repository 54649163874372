/**
 * Created by MJ
 */

import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import '../../App.css'; // Skapa en App.css fil för CSS
import { toast } from 'react-toastify';
import request from '../../utils/request';
import { useDispatch, useSelector } from 'react-redux';
import { fortnoxToken } from '../../redux/slices/fortnoxSlice';

const Fortnox = () => {
    const [activeTab, setActiveTab] = useState(1);
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const fortnoxData = useSelector(state => state.fortnox?.value?.data)
    const userData = useSelector(state => state.userData?.value)

    const handleTabClick = (tabIndex) => {
        setActiveTab(tabIndex);
    };

    const handleOptionClick = (option) => {
        if (option === 'Fortnox') {
            navigate('/dashboard/fortnoxintegration');
        }
        // Lägg till fler navigeringar här vid behov
    };

    const generateAuthorizationCode = async () => {
        const clientId = "s9nKMNRZwNgL";
        const redirectUri = "http://localhost:3000/callback";
        const state = "somestate123";
        const scope = "invoice customer article";
        const accessType = "offline";
        const responseType = "code";
        const authUrl = `https://apps.fortnox.se/oauth-v1/auth?client_id=${clientId}&scope=${scope}&state=${state}&access_type=${accessType}&response_type=${responseType}`;
        window.location.href = authUrl;
      };

    const urlParams = new URLSearchParams(window.location.search);
    const state = urlParams.get("state");
    const code = urlParams.get("code");

    const getAccessToken = async (code) => {
        console.log("getAccessToken function run", )
      try {
        console.log("getAccessToken function run before res")
        const res = await request.get(`/fortnox/fortnox-access-token?authCode=${code}`);
        console.log("getAccessToken function run after res", res)
        if (res?.data?.status) {
            dispatch(fortnoxToken(res.data))
        }
      } catch (e) {
        toast.error(e.message);
      }
    };
    
    useEffect(() => {
      if(code && state) {
        toast.success("Connected to Fortnox")
        getAccessToken(code)
      }
    },[code, state])

    const getRefreshAccessToken = async() => {
        const res = await request.post(`/fortnox/refresh-access-token?refresh_token=${fortnoxData?.refresh_token}`)
        console.log("====res", res)
        if (res?.data?.status) {
            dispatch(fortnoxToken(res.data))
        }
    }

    const getCompanyInformation = async() => {
        const res = await request.post(`fortnox/company-information`, {token: fortnoxData?.access_token})
        console.log("====res", res)
    }

    const createInvoice = async() => {
        const res = await request.post(`fortnox/create-invoice`, {token: fortnoxData?.access_token})
        console.log("====res", res)
    }

    console.log("===dddd", useSelector(state => state))

    //useEffect(() => {
        //getRefreshAccessToken()
        //getAccessToken()
        // getCompanyInformation()
        // createInvoice()
    //},[])

    return (
        <div>
            <div className='d-flex justify-content-between'>
            <h2>Integrationer</h2>
            {/* {userData?.role === "organization_admin" && */}
            <button onClick={generateAuthorizationCode}>Connect Fortnox</button>
             {/* } */}
            </div>
            <div className="tabs">
                <div
                    className={`tab ${activeTab === 1 ? 'active' : ''}`}
                    onClick={() => handleTabClick(1)}
                >
                    Ekonomisystem
                </div>
                <div
                    className={`tab ${activeTab === 2 ? 'active' : ''}`}
                    onClick={() => handleTabClick(2)}
                >
                    Grossister
                </div>
                <div
                    className={`tab ${activeTab === 3 ? 'active' : ''}`}
                    onClick={() => handleTabClick(3)}
                >
                    Rabattbrev
                </div>
            </div>
            <div id="tab-1" className={`tab-content ${activeTab === 1 ? 'active' : ''}`}>
                {activeTab === 1 && (
                    <div>
                        <h3>Välj ett alternativ:</h3>
                        <div className="options">
                            <div className="option" onClick={() => handleOptionClick('Fortnox')}>
                                Fortnox
                            </div>
                            <div className="option" onClick={() => handleOptionClick('Visma')}>
                                Visma
                            </div>
                            <div className="option" onClick={() => handleOptionClick('Björn Lunden')}>
                                Björn Lunden
                            </div>
                        </div>
                    </div>
                )}
            </div>
            <div id="tab-2" className={`tab-content ${activeTab === 2 ? 'active' : ''}`}>
                Content for Tab Two
            </div>
            <div id="tab-3" className={`tab-content ${activeTab === 3 ? 'active' : ''}`}>
                Content for Tab Three
            </div>
        </div>
    );
};

export default Fortnox;
