import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { Button, Form, InputGroup } from "react-bootstrap";
import { toast } from "react-toastify";
import { createWorkrole, updateWorkrole } from "../../redux/actions/workrolesActions";


const AddEditWorkrole = () => {
  const [hourlyRate, setHourlyRate] = useState("");
  const [name, setName] = useState()
  const [isEdit, setIsEdit] = useState(false);
  const { t } = useTranslation();
  const { state } = useLocation();

  const handleCreateAdmin = async (e) => {
    if (!hourlyRate) {
      return toast.error(t("enter_hourlyrate"))
    }
    const payload = {
      name,
      hourlyRate
    };
    try {
      if (isEdit) {
        const data = await updateWorkrole(state?.priorityData?._id, payload);
        if (data.status) {
          toast.success(t("workrole_updated_successfully"));
        }
      } else {
        const data = await createWorkrole(payload);
        if (data?.status) {
          toast.success(t("workrole_created_successfully"));
        }
      }
    } catch (error) {
      console.log("ERROR => ", error);
    }
  };

  useEffect(() => {
    const data = state?.workroleData;
    if (data) {
      setIsEdit(true);
      setHourlyRate(data.hourlyRate);
    } else {
      setIsEdit(false);
    }
  }, [state]);

  return (
    <div>
      <div className="heading">
        <h3>{t("add_workrole")}</h3>
      </div>
      <div className="row m-0 bg-white rounded py-5 px-4">
        <div className="col-md-12 mx-auto mb-0">
          <Form className="form-design">
            <div className="row gx-5">
            <div className="col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>{t("name")}</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Please enter..."
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
              <div className="col-lg-4">
                <Form.Group className="mb-3">
                  <Form.Label>{t("hourly_rate")}</Form.Label>
                  <InputGroup className="mb-3">
                    <Form.Control
                      type="text"
                      placeholder="Please enter..."
                      value={hourlyRate}
                      onChange={(e) => {
                        const value = e.target.value;
                        if (/^\d*\.?\d*$/.test(value)) {
                          setHourlyRate(value);
                        }}}
                    />
                  </InputGroup>
                </Form.Group>
              </div>
            </div>
            <div className="mt-4">
              <Button
                className="simple-btn mx-auto"
                onClick={handleCreateAdmin}
              >
                {t("submit")}
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </div>
  );
};

export default AddEditWorkrole;
