import React, { useState, useEffect } from "react";
import DataTable from "react-data-table-component";
import { Form, InputGroup } from "react-bootstrap";
import { projectList } from "../../redux/actions/projectActions";
import { useTranslation } from "react-i18next";
import { getAllworkorderTimereports } from "../../redux/actions/workorderActions";
import { useSelector } from "react-redux";
import { staffList } from "../../redux/actions/staffActions";
import { adminList } from "../../redux/actions/authActions";
import { updateWorkorderAttributes } from "../../redux/actions/workorderActions";
import moment from "moment";
import { toast } from "react-toastify";

export default function TimereportOverviewComponent() {
  const [projects, setProjects] = useState([]);
  const [timereports, setTimereports] = useState([])
  // const [staffSearchQuery, setStaffSearchQuery] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [searchColumns, setSearchColums] = useState([])
  const [timereportTypeQuery, setTimereportTypeQuery] = useState('')
  const [projectStatusQuery, setProjectStatusQuery] = useState('')
  const [selectedStaff, setSelectedStaff] = useState("");
  const user = useSelector(state => state.userData.value)
  const [allusers, setAllUsers] = useState([])
  const [fromDate, setFromDate] = useState("")
  const [toDate, setToDate] = useState("")
  const { t } = useTranslation();

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const minDate = new Date(currentYear - 5, currentDate.getMonth(), currentDate.getDate());
  const maxDate = new Date(currentYear + 5, currentDate.getMonth(), currentDate.getDate());

  function changeDayFormat(timeString) {
    const timeParts = timeString?.split(":");
    let hours = parseInt(timeParts[0], 10);
    let minutes = parseInt(timeParts[1] || "0", 10);
    if (
      isNaN(hours) ||
      isNaN(minutes) ||
      hours < 0 ||
      hours > 23 ||
      minutes < 0 ||
      minutes > 59
    ) {
      return "Invalid time format";
    }
    const formattedTime = `${hours.toString().padStart(2, "0")}:${minutes
      .toString()
      .padStart(2, "0")}`;
    return formattedTime;
  }

  function formatOvertime(input) {
    if (input === "00" || input === "" || input === "00:00") {
      return "";
    }
    const parts = input?.split(" ");
    const formattedParts = parts?.map((part) => {
      const timeParts = part.split(":");
      if (timeParts.length === 2) {
        const hours = timeParts[0];
        const minutes = timeParts[1];
        if (!isNaN(hours) && !isNaN(minutes)) {
          return `${hours.padStart(2, "0")}:${minutes.padStart(2, "0")}`;
        }
      }
      return `${part}:00`;
    });

    return formattedParts?.join(" ");
  }

  const handleTimerepotApproved = async(data) => {
       const updatedData = await updateWorkorderAttributes(
        data.workorderId,
        {...data, isApproved: !data.isApproved},
        "timeReports"
      );
      if(updatedData?.status) {
        toast.success("Completed successfully")
        getTimereportList()
      }
  }

  const columns = [
    {   
      name: <div className="table-content">{t("approve")}</div>,
      selector: (row) => (
        !row?.isApproved ?
        <input type="checkbox"
          checked={row.isApproved}
          onChange={() => handleTimerepotApproved(row)}
        />
        : ""
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("AO")}</div>,
      selector: (row) => (
        <div
          className={"jobPosition"}
        >
          {row?.aonumber}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("date")}</div>,
      selector: (row) => (
        <div
          className={
            "jobPosition"
          }
        >
          {moment(row.date).format('YYYY-MM-DD')}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("start")}</div>,
      selector: (row) => (
        <div
          className={
            "jobPosition"
          }
        >
          {row.clockTime
            ?
            moment(row?.start).format('HH:mm')
            : null}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("stop")}</div>,
      selector: (row) => (
        <div
          className={
            "jobPosition"
          }
        >
          {row.clockTime
            ?
            moment(row?.stop).format('HH:mm')
            : null}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("staff")}</div>,
      selector: (row) => (
        <div
          className={
             "jobPosition"
          }
        >
          {`${row.user?.first_name} ${row.user?.last_name}`}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("notes")}</div>,
      selector: (row) => (
        <div
          className={
            "jobPosition"
          }
        >
          {row.note}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("day")}</div>,
      selector: (row) => (
        <div
          className={
            "jobPosition"
          }
        >
          {row.routineHours
            ? changeDayFormat(row.routineHours)
            : Number(row.totalHours) > 10
              ? "10"
              : row.totalHours}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">OB1</div>,
      selector: (row) => (
        <div
          className={
            "jobPosition"
          }
        >
          {row.surcharge}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">OT1</div>,
      selector: (row) => (
        <div
          className={
             "jobPosition"
          }
        >
          {formatOvertime(row.overtime)}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">OT2</div>,
      selector: (row) => (
        <div
          className={
             "jobPosition"
          }
        >
          {formatOvertime(row.hoursOnWeekend)}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("sum")}</div>,
      selector: (row) => (
        <div
          className={
             "jobPosition"
          }
        >
          {row.clockTime ? `${row.totalHours}:00` : row.totalHours}
        </div>
      ),
      wrap: true,
    },
    {
      name: <div className="table-content">{t("Type")}</div>,
      selector: (row) => (
        <div
          className={
             "jobPosition"
          }
        >
          {row.isApproved ? "Approved" : "Not Approved"}
        </div>
      ),
      wrap: true,
    },
  ];

  const getProjectList = async () => {
    const projectData = await projectList();
    if (projectData?.status) {
      setProjects(projectData?.data);
      setSearchColums([{ label: 'Name', value: 'name' }, { label: 'Project Number', value: 'projectNumber' }, { label: 'Customer', value: 'customer' }])
    }
  };

  const getTimereportList = async() => {
    const timereports = await getAllworkorderTimereports();
    if(timereports?.status) {
       setTimereports(timereports.data.reverse())
    }
  }

  const getUsers = async() => {
       const users = await adminList()
       if(users?.status) {
        const usersList = []
        users?.data?.users.map((u) => (
          u.first_name && usersList.push(`${u.first_name} ${u.last_name}`)
        ))
        setAllUsers(usersList)
  } }
   console.log("getTimereportList", allusers)
   useEffect(() => {
    const filterData = () => {
      let data = timereports;
  
      if (fromDate && toDate) {
        const startDate = new Date(fromDate);
        const endDate = new Date(toDate);
        data = data.filter(t => {
          const reportDate = new Date(t.date);
          return reportDate >= startDate && reportDate <= endDate;
        });
      }
  
      if (timereportTypeQuery) {
        const isApproved = timereportTypeQuery === 'approved';
        data = data.filter(p => p.isApproved === isApproved);
      }
  
      if (selectedStaff) {
        const selectedStaffName = selectedStaff.toLowerCase();
        data = data.filter(item =>
          `${item.user?.first_name} ${item.user?.last_name}`.toLowerCase() === selectedStaffName
        );
      }
  
      setFilteredData(data);
    };
  
    filterData();
  }, [timereportTypeQuery, projectStatusQuery, selectedStaff, fromDate, toDate, timereports]);  


  useEffect(() => {
    getTimereportList()
    getUsers()
  }, []);

  return (
    <div className="customListing">
       <div className="d-flex align-items-center justify-content-between flex-wrap">
        <div className="heading">
          <h3>{t('timereports')}</h3>
        </div>
        <div className="d-flex align-items-center input_button">
          <div className="d-flex select-wrapper">
            <div className="d-flex align-items-center me-2">
                  <span className="white-space-nowrap me-2">From</span>
                  <InputGroup>
                    <Form.Control
                      type="date"
                      value={fromDate}
                      onChange={(e) => setFromDate(e.target.value)}
                      min={minDate.toISOString().split('T')[0]}
                      max={maxDate.toISOString().split('T')[0]}
                    />
                  </InputGroup>
              </div>
              <div className="d-flex align-items-center">
                  <span className="white-space-nowrap me-2">To</span>
                  <InputGroup>
                    <Form.Control
                      type="date"
                      value={toDate}
                      onChange={(e) => setToDate(e.target.value)}
                      min={minDate.toISOString().split('T')[0]}
                      max={maxDate.toISOString().split('T')[0]}
                    />
                  </InputGroup>
              </div>
            <select className="form-control" 
              onChange={(e) => (setTimereportTypeQuery(e.target.value))}
              >
              <option value="">All</option>
              <option value='approved'>Approved</option>
              <option value='notapproved'>Not Approved</option>
            </select>
            <select className="form-control" 
            // onChange={(e) => (setProjectStatusQuery(e.target.value), setProjectTypeQuery(''), setSearchQuery(''))}
            >
              <option value=''>Status</option>
              <option value='Not started'>Not started</option>
              <option value='In progress'>In progress</option>
              <option value='Completed'>Completed</option>
            </select>
             <select className="form-control" 
               onChange={(e) => (setSelectedStaff(e.target.value))}
            >
              <option value=''>Select employee</option>
              {allusers?.map((u) => (
                <option value={u}>{u}</option>
              ))}

            </select>
          </div>
         
        </div>
      </div>
      <div className="table-responsive alternative-colors mb-5 rounded-0">
        <DataTable 
        columns={columns} 
        data={(timereportTypeQuery || selectedStaff || (fromDate && toDate)) ? filteredData : timereports} 
        responsive={true} 
        paginationPerPage={40} 
        paginationRowsPerPageOptions={[10, 20, 30, 40]} />
      </div>
    </div>
  );
}
