import "./sidebars.scss";

import {useDispatch} from "react-redux";
import {user} from "../../redux/slices/userSlice";
import {useTranslation} from "react-i18next";
import { useSelector } from "react-redux";
import {Menu, MenuItem, Sidebar, SubMenu} from "react-pro-sidebar";
import {Link} from "react-router-dom";
import {useState} from "react";
import avatar from "../../assets/images/logo.png"
import langIcon from "../../assets/images/language.svg"
import engFlag from "../../assets/images/flag.png"

export const Sidebars = ({sideBarHandler}) => {
    const [collapsed, setCollapsed] = useState(false);
    const [activeTab, setActiveTab] = useState("Register");
    const currentUser = useSelector(state => state.userData?.value)
    const dispatch = useDispatch();
    const {t} = useTranslation();
    const { i18n } = useTranslation();

    const handleItemClick = () => {
        if (window.innerWidth < 768) {
            sideBarHandler();
        }
    };

    const handleLogout = () => {
        localStorage.removeItem("otid_user_token");
        localStorage.removeItem("otid_user");
        dispatch(user(""));
    };

    const handleLanguageChange = (e) => {
        localStorage.setItem("otidLang", e);
        i18n.changeLanguage(e);
    }

    return (
        <>
            <Sidebar collapsed={collapsed}>
                <Menu>
                    <MenuItem
                        component={<Link to="/dashboard/work-order-list"/>}
                        icon={
                            <i>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 26 26"
                                    style={{fill: "rgba(0, 0, 0, 1)"}}
                                >
                                    <path
                                        d="M10 3H4a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1zM9 9H5V5h4v4zm5 2h6a1 1 0 0 0 1-1V4a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v6a1 1 0 0 0 1 1zm1-6h4v4h-4V5zM3 20a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1H4a1 1 0 0 0-1 1v6zm2-5h4v4H5v-4zm8 5a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1v-6a1 1 0 0 0-1-1h-6a1 1 0 0 0-1 1v6zm2-5h4v4h-4v-4z"></path>
                                </svg>
                                {" "}
                            </i>
                        }
                    >
                        {t("work_bank")}
                    </MenuItem>
                    <SubMenu
                        label={t("register")}
                        icon={
                            <i>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 26 26"
                                    style={{fill: "rgba(0, 0, 0, 1)"}}
                                >
                                    <path
                                        d="M20 3H4c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM4 19V5h16l.002 14H4z"></path>
                                    <path d="M6 7h12v2H6zm0 4h12v2H6zm0 4h6v2H6z"></path>
                                </svg>
                            </i>
                        }
                        defaultOpen={false}
                        onClick={() => setActiveTab("Register")}
                        className={`toggle-icon ${activeTab === "Register" ? "active" : ""
                        }`}
                        suffix={
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                style={{fill: "rgba(0, 0, 0, 1)"}}
                            >
                                <path
                                    d="M5.536 21.886a1.004 1.004 0 0 0 1.033-.064l13-9a1 1 0 0 0 0-1.644l-13-9A.998.998 0 0 0 5 3v18a1 1 0 0 0 .536.886zM7 4.909 17.243 12 7 19.091V4.909z"></path>
                            </svg>
                        }
                    >
                        <MenuItem
                            component={
                                <Link to="/dashboard/staff-listing" onClick={handleItemClick}/>
                            }
                            icon={
                                <i>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 26 26"
                                        style={{fill: "rgba(0, 0, 0, 1)"}}
                                    >
                                        <path
                                            d="M15 11h7v2h-7zm1 4h6v2h-6zm-2-8h8v2h-8zM4 19h10v-1c0-2.757-2.243-5-5-5H7c-2.757 0-5 2.243-5 5v1h2zm4-7c1.995 0 3.5-1.505 3.5-3.5S9.995 5 8 5 4.5 6.505 4.5 8.5 6.005 12 8 12z"></path>
                                    </svg>
                                </i>
                            }
                        >
                            {t("staff")}
                        </MenuItem>
                        <MenuItem
                            component={
                                <Link
                                    to="/dashboard/customer-listing"
                                    onClick={handleItemClick}
                                />
                            }
                            icon={
                                <i>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 26 26"
                                        style={{fill: "rgba(0, 0, 0, 1)"}}
                                    >
                                        <path
                                            d="M20 6h-3V4c0-1.103-.897-2-2-2H9c-1.103 0-2 .897-2 2v2H4c-1.103 0-2 .897-2 2v11c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V8c0-1.103-.897-2-2-2zm-5-2v2H9V4h6zM8 8h12v3H4V8h4zM4 19v-6h6v2h4v-2h6l.001 6H4z"></path>
                                    </svg>
                                </i>
                            }
                        >
                            {t("customer")}
                        </MenuItem>
                        <MenuItem
                            component={
                                <Link to="/dashboard/self-controls" onClick={handleItemClick}/>
                            }
                            icon={
                                <i>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="22"
                                        height="22"
                                        viewBox="0 0 26 26"
                                        style={{fill: "rgba(0, 0, 0, 1)"}}
                                    >
                                        <path
                                            d="M7 5c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2V7c0-1.103-.897-2-2-2H7zm0 12V7h10l.002 10H7z"></path>
                                        <path
                                            d="M10.996 12.556 9.7 11.285l-1.4 1.43 2.704 2.647 4.699-4.651-1.406-1.422z"></path>
                                    </svg>
                                </i>
                            }
                        >
                            {t("self_control")}
                        </MenuItem>
                        <MenuItem
                            component={
                                <Link
                                    to="/dashboard/self-control-list"
                                    onClick={handleItemClick}
                                />
                            }
                            icon={
                                <i>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="22"
                                        height="22"
                                        viewBox="0 0 26 26"
                                        style={{fill: "rgba(0, 0, 0, 1)"}}
                                    >
                                        <path
                                            d="M7 5c-1.103 0-2 .897-2 2v10c0 1.103.897 2 2 2h10c1.103 0 2-.897 2-2V7c0-1.103-.897-2-2-2H7zm0 12V7h10l.002 10H7z"></path>
                                        <path
                                            d="M10.996 12.556 9.7 11.285l-1.4 1.43 2.704 2.647 4.699-4.651-1.406-1.422z"></path>
                                    </svg>
                                </i>
                            }
                        >
                            {t("self_control_list")}
                        </MenuItem>

                        {/* <MenuItem
              component={
                <Link
                  to="/dashboard/facilities-list"
                  onClick={handleItemClick}
                />
              }
            >
              <img src={icon2} alt="icon" /> Facilities
            </MenuItem> */}
                        <MenuItem
                            icon={
                                <i>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 26 26"
                                        style={{fill: "rgba(0, 0, 0, 1)"}}
                                    >
                                        <path
                                            d="M20.29 8.29 16 12.58l-1.3-1.29-1.41 1.42 2.7 2.7 5.72-5.7zM4 8a3.91 3.91 0 0 0 4 4 3.91 3.91 0 0 0 4-4 3.91 3.91 0 0 0-4-4 3.91 3.91 0 0 0-4 4zm6 0a1.91 1.91 0 0 1-2 2 1.91 1.91 0 0 1-2-2 1.91 1.91 0 0 1 2-2 1.91 1.91 0 0 1 2 2zM4 18a3 3 0 0 1 3-3h2a3 3 0 0 1 3 3v1h2v-1a5 5 0 0 0-5-5H7a5 5 0 0 0-5 5v1h2z"></path>
                                    </svg>
                                </i>
                            }
                        >
                            {t("stock")}
                        </MenuItem>
                        <MenuItem
                            icon={
                                <i>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 26 26"
                                        style={{fill: "rgba(0, 0, 0, 1)"}}
                                    >
                                        <path
                                            d="M20.29 8.29 16 12.58l-1.3-1.29-1.41 1.42 2.7 2.7 5.72-5.7zM4 8a3.91 3.91 0 0 0 4 4 3.91 3.91 0 0 0 4-4 3.91 3.91 0 0 0-4-4 3.91 3.91 0 0 0-4 4zm6 0a1.91 1.91 0 0 1-2 2 1.91 1.91 0 0 1-2-2 1.91 1.91 0 0 1 2-2 1.91 1.91 0 0 1 2 2zM4 18a3 3 0 0 1 3-3h2a3 3 0 0 1 3 3v1h2v-1a5 5 0 0 0-5-5H7a5 5 0 0 0-5 5v1h2z"></path>
                                    </svg>
                                </i>
                            }
                        >
                            {t("e_delivery_note")}
                        </MenuItem>
                        
                        <MenuItem
                            icon={
                                <i>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 26 26"
                                        style={{fill: "rgba(0, 0, 0, 1)"}}
                                    >
                                        <path
                                            d="M20.29 8.29 16 12.58l-1.3-1.29-1.41 1.42 2.7 2.7 5.72-5.7zM4 8a3.91 3.91 0 0 0 4 4 3.91 3.91 0 0 0 4-4 3.91 3.91 0 0 0-4-4 3.91 3.91 0 0 0-4 4zm6 0a1.91 1.91 0 0 1-2 2 1.91 1.91 0 0 1-2-2 1.91 1.91 0 0 1 2-2 1.91 1.91 0 0 1 2 2zM4 18a3 3 0 0 1 3-3h2a3 3 0 0 1 3 3v1h2v-1a5 5 0 0 0-5-5H7a5 5 0 0 0-5 5v1h2z"></path>
                                    </svg>
                                </i>
                            }
                        >
                            {t("upload_discount_later")}
                        </MenuItem>
                        <MenuItem
                            component={
                                <Link
                                    to="/dashboard/project-listing"
                                    onClick={handleItemClick}
                                />
                            }
                            icon={
                                <i>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 26 26"
                                        style={{fill: "rgba(0, 0, 0, 1)"}}
                                    >
                                        <path
                                            d="M19 3c-1.654 0-3 1.346-3 3 0 .502.136.968.354 1.385l-1.116 1.302A3.976 3.976 0 0 0 13 8c-.739 0-1.425.216-2.02.566L9.566 7.152A3.449 3.449 0 0 0 10 5.5C10 3.57 8.43 2 6.5 2S3 3.57 3 5.5 4.57 9 6.5 9c.601 0 1.158-.166 1.652-.434L9.566 9.98A3.972 3.972 0 0 0 9 12c0 .997.38 1.899.985 2.601l-1.692 1.692.025.025A2.962 2.962 0 0 0 7 16c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3c0-.476-.121-.919-.318-1.318l.025.025 1.954-1.954c.421.15.867.247 1.339.247 2.206 0 4-1.794 4-4a3.96 3.96 0 0 0-.439-1.785l1.253-1.462c.364.158.764.247 1.186.247 1.654 0 3-1.346 3-3s-1.346-3-3-3zM7 20a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM5 5.5C5 4.673 5.673 4 6.5 4S8 4.673 8 5.5 7.327 7 6.5 7 5 6.327 5 5.5zm8 8.5c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm6-7a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path>
                                    </svg>
                                </i>
                            }
                        >
                            {t("project")}
                        </MenuItem>
                        <MenuItem
                            component={
                                <Link
                                    to="/dashboard/priority-list"
                                    onClick={handleItemClick}
                                />
                            }
                            icon={
                                <i>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 26 26"
                                        style={{fill: "rgba(0, 0, 0, 1)"}}
                                    >
                                        <path
                                            d="M19 3c-1.654 0-3 1.346-3 3 0 .502.136.968.354 1.385l-1.116 1.302A3.976 3.976 0 0 0 13 8c-.739 0-1.425.216-2.02.566L9.566 7.152A3.449 3.449 0 0 0 10 5.5C10 3.57 8.43 2 6.5 2S3 3.57 3 5.5 4.57 9 6.5 9c.601 0 1.158-.166 1.652-.434L9.566 9.98A3.972 3.972 0 0 0 9 12c0 .997.38 1.899.985 2.601l-1.692 1.692.025.025A2.962 2.962 0 0 0 7 16c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3c0-.476-.121-.919-.318-1.318l.025.025 1.954-1.954c.421.15.867.247 1.339.247 2.206 0 4-1.794 4-4a3.96 3.96 0 0 0-.439-1.785l1.253-1.462c.364.158.764.247 1.186.247 1.654 0 3-1.346 3-3s-1.346-3-3-3zM7 20a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM5 5.5C5 4.673 5.673 4 6.5 4S8 4.673 8 5.5 7.327 7 6.5 7 5 6.327 5 5.5zm8 8.5c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm6-7a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path>
                                    </svg>
                                </i>
                            }
                        >
                            {t("priority")}
                        </MenuItem>
                        <MenuItem
                            component={
                                <Link
                                    to="/dashboard/organization-list"
                                    onClick={handleItemClick}
                                />
                            }
                            icon={
                                <i>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 26 26"
                                        style={{fill: "rgba(0, 0, 0, 1)"}}
                                    >
                                        <path
                                            d="M19 3c-1.654 0-3 1.346-3 3 0 .502.136.968.354 1.385l-1.116 1.302A3.976 3.976 0 0 0 13 8c-.739 0-1.425.216-2.02.566L9.566 7.152A3.449 3.449 0 0 0 10 5.5C10 3.57 8.43 2 6.5 2S3 3.57 3 5.5 4.57 9 6.5 9c.601 0 1.158-.166 1.652-.434L9.566 9.98A3.972 3.972 0 0 0 9 12c0 .997.38 1.899.985 2.601l-1.692 1.692.025.025A2.962 2.962 0 0 0 7 16c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3c0-.476-.121-.919-.318-1.318l.025.025 1.954-1.954c.421.15.867.247 1.339.247 2.206 0 4-1.794 4-4a3.96 3.96 0 0 0-.439-1.785l1.253-1.462c.364.158.764.247 1.186.247 1.654 0 3-1.346 3-3s-1.346-3-3-3zM7 20a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM5 5.5C5 4.673 5.673 4 6.5 4S8 4.673 8 5.5 7.327 7 6.5 7 5 6.327 5 5.5zm8 8.5c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm6-7a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path>
                                    </svg>
                                </i>
                            }
                        >
                            {t("organization")}
                        </MenuItem>
                        <MenuItem
                            component={
                                <Link
                                    to="/dashboard/favorite-lists"
                                    onClick={handleItemClick}
                                />
                            }
                            icon={
                                <i>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 26 26"
                                        style={{fill: "rgba(0, 0, 0, 1)"}}
                                    >
                                        <path
                                            d="M17.24 19.399v-4.804h1.6V21H4.381v-6.405h1.598v4.804H17.24zM7.582 17.8h8.055v-1.604H7.582V17.8zm.195-3.64 7.859 1.641.34-1.552-7.861-1.642-.338 1.553zm1.018-3.794 7.281 3.398.678-1.463-7.281-3.399-.678 1.454v.01zm2.037-3.589 6.166 5.142 1.018-1.216-6.162-5.14-1.016 1.213-.006.001zm3.982-3.778-1.311.969 4.803 6.454 1.313-.971-4.807-6.452h.002z"></path>
                                    </svg>
                                </i>
                            }
                        >
                            {t("Favorite List")}
                        </MenuItem>
                        <MenuItem
                            component={
                                <Link
                                    to="/dashboard/workrole-list"
                                    onClick={handleItemClick}
                                />
                            }
                            icon={
                                <i>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 26 26"
                                        style={{fill: "rgba(0, 0, 0, 1)"}}
                                    >
                                        <path
                                            d="M17.24 19.399v-4.804h1.6V21H4.381v-6.405h1.598v4.804H17.24zM7.582 17.8h8.055v-1.604H7.582V17.8zm.195-3.64 7.859 1.641.34-1.552-7.861-1.642-.338 1.553zm1.018-3.794 7.281 3.398.678-1.463-7.281-3.399-.678 1.454v.01zm2.037-3.589 6.166 5.142 1.018-1.216-6.162-5.14-1.016 1.213-.006.001zm3.982-3.778-1.311.969 4.803 6.454 1.313-.971-4.807-6.452h.002z"></path>
                                    </svg>
                                </i>
                            }
                        >
                            {t("Workrole")}
                        </MenuItem>
                    </SubMenu>
                    <SubMenu
                        label={t("overview")}
                        icon={
                            <i>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 26 26"
                                    style={{fill: "rgba(0, 0, 0, 1)"}}
                                >
                                    <path
                                        d="M20 3H4c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM4 19V5h16l.002 14H4z"></path>
                                    <path d="M6 7h12v2H6zm0 4h12v2H6zm0 4h6v2H6z"></path>
                                </svg>
                            </i>
                        }
                        defaultOpen={false}
                        onClick={() => setActiveTab("Overview")}
                        className={`toggle-icon ${activeTab === "Overview" ? "active" : ""
                        }`}
                        suffix={
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                style={{fill: "rgba(0, 0, 0, 1)"}}
                            >
                                <path
                                    d="M5.536 21.886a1.004 1.004 0 0 0 1.033-.064l13-9a1 1 0 0 0 0-1.644l-13-9A.998.998 0 0 0 5 3v18a1 1 0 0 0 .536.886zM7 4.909 17.243 12 7 19.091V4.909z"></path>
                            </svg>
                        }
                    >
                        <MenuItem
                            component={
                                <Link
                                   to="timereport-overview"
                                   onClick={handleItemClick}
                                />
                            }
                            icon={
                                <i>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 26 26"
                                        style={{fill: "rgba(0, 0, 0, 1)"}}
                                    >
                                        <path
                                            d="M20 6h-3V4c0-1.103-.897-2-2-2H9c-1.103 0-2 .897-2 2v2H4c-1.103 0-2 .897-2 2v11c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V8c0-1.103-.897-2-2-2zm-5-2v2H9V4h6zM8 8h12v3H4V8h4zM4 19v-6h6v2h4v-2h6l.001 6H4z"></path>
                                    </svg>
                                </i>
                            }
                        >
                            {t("timereport_overview")}
                        </MenuItem>
                        <MenuItem
                            icon={
                                <i>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 26 26"
                                        style={{fill: "rgba(0, 0, 0, 1)"}}
                                    >
                                        <path
                                            d="M20.29 8.29 16 12.58l-1.3-1.29-1.41 1.42 2.7 2.7 5.72-5.7zM4 8a3.91 3.91 0 0 0 4 4 3.91 3.91 0 0 0 4-4 3.91 3.91 0 0 0-4-4 3.91 3.91 0 0 0-4 4zm6 0a1.91 1.91 0 0 1-2 2 1.91 1.91 0 0 1-2-2 1.91 1.91 0 0 1 2-2 1.91 1.91 0 0 1 2 2zM4 18a3 3 0 0 1 3-3h2a3 3 0 0 1 3 3v1h2v-1a5 5 0 0 0-5-5H7a5 5 0 0 0-5 5v1h2z"></path>
                                    </svg>
                                </i>
                            }
                        >
                            {t("submitted_time_reports")}
                        </MenuItem>
                        <MenuItem
                            icon={
                                <i>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 26 26"
                                        style={{fill: "rgba(0, 0, 0, 1)"}}
                                    >
                                        <path
                                            d="M19 3c-1.654 0-3 1.346-3 3 0 .502.136.968.354 1.385l-1.116 1.302A3.976 3.976 0 0 0 13 8c-.739 0-1.425.216-2.02.566L9.566 7.152A3.449 3.449 0 0 0 10 5.5C10 3.57 8.43 2 6.5 2S3 3.57 3 5.5 4.57 9 6.5 9c.601 0 1.158-.166 1.652-.434L9.566 9.98A3.972 3.972 0 0 0 9 12c0 .997.38 1.899.985 2.601l-1.692 1.692.025.025A2.962 2.962 0 0 0 7 16c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3c0-.476-.121-.919-.318-1.318l.025.025 1.954-1.954c.421.15.867.247 1.339.247 2.206 0 4-1.794 4-4a3.96 3.96 0 0 0-.439-1.785l1.253-1.462c.364.158.764.247 1.186.247 1.654 0 3-1.346 3-3s-1.346-3-3-3zM7 20a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM5 5.5C5 4.673 5.673 4 6.5 4S8 4.673 8 5.5 7.327 7 6.5 7 5 6.327 5 5.5zm8 8.5c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm6-7a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path>
                                    </svg>
                                </i>
                            }
                        >
                            {t("submitted_deviation")}
                        </MenuItem>
                    </SubMenu>
                    <SubMenu
                        label={t("scheduling")}
                        icon={
                            <i>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 26 26"
                                    style={{fill: "rgba(0, 0, 0, 1)"}}
                                >
                                    <path
                                        d="M20 3H4c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM4 19V5h16l.002 14H4z"></path>
                                    <path d="M6 7h12v2H6zm0 4h12v2H6zm0 4h6v2H6z"></path>
                                </svg>
                            </i>
                        }
                        defaultOpen={false}
                        onClick={() => setActiveTab("Scheduling")}
                        className={`toggle-icon ${activeTab === "Scheduling" ? "active" : ""
                        }`}
                        suffix={
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                style={{fill: "rgba(0, 0, 0, 1)"}}
                            >
                                <path
                                    d="M5.536 21.886a1.004 1.004 0 0 0 1.033-.064l13-9a1 1 0 0 0 0-1.644l-13-9A.998.998 0 0 0 5 3v18a1 1 0 0 0 .536.886zM7 4.909 17.243 12 7 19.091V4.909z"></path>
                            </svg>
                        }
                    >
                        <MenuItem
                            icon={
                                <i>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 26 26"
                                        style={{fill: "rgba(0, 0, 0, 1)"}}
                                    >
                                        <path
                                            d="M20 6h-3V4c0-1.103-.897-2-2-2H9c-1.103 0-2 .897-2 2v2H4c-1.103 0-2 .897-2 2v11c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V8c0-1.103-.897-2-2-2zm-5-2v2H9V4h6zM8 8h12v3H4V8h4zM4 19v-6h6v2h4v-2h6l.001 6H4z"></path>
                                    </svg>
                                </i>
                            }
                        >
                            {t("add_work_shift")}
                        </MenuItem>
                        <MenuItem
                            icon={
                                <i>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 26 26"
                                        style={{fill: "rgba(0, 0, 0, 1)"}}
                                    >
                                        <path
                                            d="M15 11h7v2h-7zm1 4h6v2h-6zm-2-8h8v2h-8zM4 19h10v-1c0-2.757-2.243-5-5-5H7c-2.757 0-5 2.243-5 5v1h2zm4-7c1.995 0 3.5-1.505 3.5-3.5S9.995 5 8 5 4.5 6.505 4.5 8.5 6.005 12 8 12z"></path>
                                    </svg>
                                </i>
                            }
                        >
                            {t("Schedule")}
                        </MenuItem>
                        <MenuItem
                            icon={
                                <i>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 26 26"
                                        style={{fill: "rgba(0, 0, 0, 1)"}}
                                    >
                                        <path
                                            d="M17.24 19.399v-4.804h1.6V21H4.381v-6.405h1.598v4.804H17.24zM7.582 17.8h8.055v-1.604H7.582V17.8zm.195-3.64 7.859 1.641.34-1.552-7.861-1.642-.338 1.553zm1.018-3.794 7.281 3.398.678-1.463-7.281-3.399-.678 1.454v.01zm2.037-3.589 6.166 5.142 1.018-1.216-6.162-5.14-1.016 1.213-.006.001zm3.982-3.778-1.311.969 4.803 6.454 1.313-.971-4.807-6.452h.002z"></path>
                                    </svg>
                                </i>
                            }
                        >
                            {t("main_booking")}
                        </MenuItem>
                        <MenuItem
                            icon={
                                <i>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 26 26"
                                        style={{fill: "rgba(0, 0, 0, 1)"}}
                                    >
                                        <path
                                            d="M20.29 8.29 16 12.58l-1.3-1.29-1.41 1.42 2.7 2.7 5.72-5.7zM4 8a3.91 3.91 0 0 0 4 4 3.91 3.91 0 0 0 4-4 3.91 3.91 0 0 0-4-4 3.91 3.91 0 0 0-4 4zm6 0a1.91 1.91 0 0 1-2 2 1.91 1.91 0 0 1-2-2 1.91 1.91 0 0 1 2-2 1.91 1.91 0 0 1 2 2zM4 18a3 3 0 0 1 3-3h2a3 3 0 0 1 3 3v1h2v-1a5 5 0 0 0-5-5H7a5 5 0 0 0-5 5v1h2z"></path>
                                    </svg>
                                </i>
                            }
                        >
                            {t("staff_assignment")}
                        </MenuItem>
                        <MenuItem
                            component={
                                <Link
                                    to="/dashboard/work-schedule"
                                    onClick={handleItemClick}
                                />
                            }
                            icon={
                                <i>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 26 26"
                                        style={{fill: "rgba(0, 0, 0, 1)"}}
                                    >
                                        <path
                                            d="M19 3c-1.654 0-3 1.346-3 3 0 .502.136.968.354 1.385l-1.116 1.302A3.976 3.976 0 0 0 13 8c-.739 0-1.425.216-2.02.566L9.566 7.152A3.449 3.449 0 0 0 10 5.5C10 3.57 8.43 2 6.5 2S3 3.57 3 5.5 4.57 9 6.5 9c.601 0 1.158-.166 1.652-.434L9.566 9.98A3.972 3.972 0 0 0 9 12c0 .997.38 1.899.985 2.601l-1.692 1.692.025.025A2.962 2.962 0 0 0 7 16c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3c0-.476-.121-.919-.318-1.318l.025.025 1.954-1.954c.421.15.867.247 1.339.247 2.206 0 4-1.794 4-4a3.96 3.96 0 0 0-.439-1.785l1.253-1.462c.364.158.764.247 1.186.247 1.654 0 3-1.346 3-3s-1.346-3-3-3zM7 20a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM5 5.5C5 4.673 5.673 4 6.5 4S8 4.673 8 5.5 7.327 7 6.5 7 5 6.327 5 5.5zm8 8.5c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm6-7a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path>
                                    </svg>
                                </i>
                            }
                        >
                            {t("work_schedule")}
                        </MenuItem>
                    </SubMenu>
                    <SubMenu
                        label={t("billing")}
                        icon={
                            <i>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 26 26"
                                    style={{fill: "rgba(0, 0, 0, 1)"}}
                                >
                                    <path
                                        d="M20 3H4c-1.103 0-2 .897-2 2v14c0 1.103.897 2 2 2h16c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2zM4 19V5h16l.002 14H4z"></path>
                                    <path d="M6 7h12v2H6zm0 4h12v2H6zm0 4h6v2H6z"></path>
                                </svg>
                            </i>
                        }
                        defaultOpen={false}
                        onClick={() => setActiveTab("Billing")}
                        className={`toggle-icon ${activeTab === "Billing" ? "active" : ""
                        }`}
                        suffix={
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                style={{fill: "rgba(0, 0, 0, 1)"}}
                            >
                                <path
                                    d="M5.536 21.886a1.004 1.004 0 0 0 1.033-.064l13-9a1 1 0 0 0 0-1.644l-13-9A.998.998 0 0 0 5 3v18a1 1 0 0 0 .536.886zM7 4.909 17.243 12 7 19.091V4.909z"></path>
                            </svg>
                        }
                    >
                        <MenuItem
                            icon={
                                <i>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 26 26"
                                        style={{fill: "rgba(0, 0, 0, 1)"}}
                                    >
                                        <path
                                            d="M15 11h7v2h-7zm1 4h6v2h-6zm-2-8h8v2h-8zM4 19h10v-1c0-2.757-2.243-5-5-5H7c-2.757 0-5 2.243-5 5v1h2zm4-7c1.995 0 3.5-1.505 3.5-3.5S9.995 5 8 5 4.5 6.505 4.5 8.5 6.005 12 8 12z"></path>
                                    </svg>
                                </i>
                            }
                        >
                            {t("price_lists")}
                        </MenuItem>
                        <MenuItem
                            icon={
                                <i>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 26 26"
                                        style={{fill: "rgba(0, 0, 0, 1)"}}
                                    >
                                        <path
                                            d="M20.29 8.29 16 12.58l-1.3-1.29-1.41 1.42 2.7 2.7 5.72-5.7zM4 8a3.91 3.91 0 0 0 4 4 3.91 3.91 0 0 0 4-4 3.91 3.91 0 0 0-4-4 3.91 3.91 0 0 0-4 4zm6 0a1.91 1.91 0 0 1-2 2 1.91 1.91 0 0 1-2-2 1.91 1.91 0 0 1 2-2 1.91 1.91 0 0 1 2 2zM4 18a3 3 0 0 1 3-3h2a3 3 0 0 1 3 3v1h2v-1a5 5 0 0 0-5-5H7a5 5 0 0 0-5 5v1h2z"></path>
                                    </svg>
                                </i>
                            }
                        >
                            {t("pre_invoices")}
                        </MenuItem>
                        <MenuItem
                            icon={
                                <i>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 26 26"
                                        style={{fill: "rgba(0, 0, 0, 1)"}}
                                    >
                                        <path
                                            d="M19 3c-1.654 0-3 1.346-3 3 0 .502.136.968.354 1.385l-1.116 1.302A3.976 3.976 0 0 0 13 8c-.739 0-1.425.216-2.02.566L9.566 7.152A3.449 3.449 0 0 0 10 5.5C10 3.57 8.43 2 6.5 2S3 3.57 3 5.5 4.57 9 6.5 9c.601 0 1.158-.166 1.652-.434L9.566 9.98A3.972 3.972 0 0 0 9 12c0 .997.38 1.899.985 2.601l-1.692 1.692.025.025A2.962 2.962 0 0 0 7 16c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3c0-.476-.121-.919-.318-1.318l.025.025 1.954-1.954c.421.15.867.247 1.339.247 2.206 0 4-1.794 4-4a3.96 3.96 0 0 0-.439-1.785l1.253-1.462c.364.158.764.247 1.186.247 1.654 0 3-1.346 3-3s-1.346-3-3-3zM7 20a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM5 5.5C5 4.673 5.673 4 6.5 4S8 4.673 8 5.5 7.327 7 6.5 7 5 6.327 5 5.5zm8 8.5c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm6-7a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path>
                                    </svg>
                                </i>
                            }
                        >
                            {t("invoice_profile")}
                        </MenuItem>
                        <MenuItem
                            icon={
                                <i>
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        viewBox="0 0 26 26"
                                        style={{fill: "rgba(0, 0, 0, 1)"}}
                                    >
                                        <path
                                            d="M19 3c-1.654 0-3 1.346-3 3 0 .502.136.968.354 1.385l-1.116 1.302A3.976 3.976 0 0 0 13 8c-.739 0-1.425.216-2.02.566L9.566 7.152A3.449 3.449 0 0 0 10 5.5C10 3.57 8.43 2 6.5 2S3 3.57 3 5.5 4.57 9 6.5 9c.601 0 1.158-.166 1.652-.434L9.566 9.98A3.972 3.972 0 0 0 9 12c0 .997.38 1.899.985 2.601l-1.692 1.692.025.025A2.962 2.962 0 0 0 7 16c-1.654 0-3 1.346-3 3s1.346 3 3 3 3-1.346 3-3c0-.476-.121-.919-.318-1.318l.025.025 1.954-1.954c.421.15.867.247 1.339.247 2.206 0 4-1.794 4-4a3.96 3.96 0 0 0-.439-1.785l1.253-1.462c.364.158.764.247 1.186.247 1.654 0 3-1.346 3-3s-1.346-3-3-3zM7 20a1 1 0 1 1 0-2 1 1 0 0 1 0 2zM5 5.5C5 4.673 5.673 4 6.5 4S8 4.673 8 5.5 7.327 7 6.5 7 5 6.327 5 5.5zm8 8.5c-1.103 0-2-.897-2-2s.897-2 2-2 2 .897 2 2-.897 2-2 2zm6-7a1 1 0 1 1 0-2 1 1 0 0 1 0 2z"></path>
                                    </svg>
                                </i>
                            }
                        >
                            {t("account_settings")}
                        </MenuItem>
                    </SubMenu>
                    <MenuItem
                        component={<Link to="/dashboard/admins-list"/>}
                        icon={
                            <i>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 26 26"
                                    style={{fill: "rgba(0, 0, 0, 1)"}}
                                >
                                    <path
                                        d="M12 2A10.13 10.13 0 0 0 2 12a10 10 0 0 0 4 7.92V20h.1a9.7 9.7 0 0 0 11.8 0h.1v-.08A10 10 0 0 0 22 12 10.13 10.13 0 0 0 12 2zM8.07 18.93A3 3 0 0 1 11 16.57h2a3 3 0 0 1 2.93 2.36 7.75 7.75 0 0 1-7.86 0zm9.54-1.29A5 5 0 0 0 13 14.57h-2a5 5 0 0 0-4.61 3.07A8 8 0 0 1 4 12a8.1 8.1 0 0 1 8-8 8.1 8.1 0 0 1 8 8 8 8 0 0 1-2.39 5.64z"></path>
                                    <path
                                        d="M12 6a3.91 3.91 0 0 0-4 4 3.91 3.91 0 0 0 4 4 3.91 3.91 0 0 0 4-4 3.91 3.91 0 0 0-4-4zm0 6a1.91 1.91 0 0 1-2-2 1.91 1.91 0 0 1 2-2 1.91 1.91 0 0 1 2 2 1.91 1.91 0 0 1-2 2z"></path>
                                </svg>
                                {" "}
                            </i>
                        }
                    >
                        {t("admin")}
                    </MenuItem>
                    <MenuItem
                        component={<Link to="/dashboard/users-management"/>}
                        icon={
                            <i>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 26 26"
                                    style={{fill: "rgba(0, 0, 0, 1)"}}
                                >
                                    <path
                                        d="M12 4c1.654 0 3 1.346 3 3h2c0-2.757-2.243-5-5-5S7 4.243 7 7v2H6c-1.103 0-2 .897-2 2v9c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-9c0-1.103-.897-2-2-2H9V7c0-1.654 1.346-3 3-3zm6.002 16H13v-2.278c.595-.347 1-.985 1-1.722 0-1.103-.897-2-2-2s-2 .897-2 2c0 .736.405 1.375 1 1.722V20H6v-9h12l.002 9z"></path>
                                </svg>
                                {" "}
                            </i>
                        }
                    >
                        {t("unlocking")}
                    </MenuItem>
                    <MenuItem
                        component={<Link to="/dashboard/fortnox"/>}
                        onClick={handleItemClick}
                        icon={
                            <i>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 26 26"
                                    style={{fill: "rgba(0, 0, 0, 1)"}}
                                >
                                    <path
                                        d="M12 4c1.654 0 3 1.346 3 3h2c0-2.757-2.243-5-5-5S7 4.243 7 7v2H6c-1.103 0-2 .897-2 2v9c0 1.103.897 2 2 2h12c1.103 0 2-.897 2-2v-9c0-1.103-.897-2-2-2H9V7c0-1.654 1.346-3 3-3zm6.002 16H13v-2.278c.595-.347 1-.985 1-1.722 0-1.103-.897-2-2-2s-2 .897-2 2c0 .736.405 1.375 1 1.722V20H6v-9h12l.002 9z"></path>
                                </svg>
                                {" "}
                            </i>
                        }
                    >
                        {t("settings")}
                    </MenuItem>
                    <MenuItem
                        component={<Link to="/"/>}
                        onClick={handleLogout}
                        icon={
                            <i>
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    width="24"
                                    height="24"
                                    viewBox="0 0 26 26"
                                    style={{fill: "rgba(0, 0, 0, 1)"}}
                                >
                                    <path d="M16 13v-2H7V8l-5 4 5 4v-3z"></path>
                                    <path
                                        d="M20 3h-9c-1.103 0-2 .897-2 2v4h2V5h9v14h-9v-4H9v4c0 1.103.897 2 2 2h9c1.103 0 2-.897 2-2V5c0-1.103-.897-2-2-2z"></path>
                                </svg>
                            </i>
                        }
                    >
                        <span> {t("logout")}</span>
                    </MenuItem>

                    <SubMenu
                        label={t("Language")}
                        icon={
                            <i>
                                <img src={langIcon} alt="" />
                            </i>
                        }
                        defaultOpen={false}
                        onClick={() => setActiveTab("Overview")}
                        className={`toggle-icon ${activeTab === "Overview" ? "active" : ""
                        }`}
                        suffix={
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="40"
                                height="40"
                                viewBox="0 0 40 40"
                                style={{fill: "rgba(0, 0, 0, 1)"}}
                            >
                                <path
                                    d="M5.536 21.886a1.004 1.004 0 0 0 1.033-.064l13-9a1 1 0 0 0 0-1.644l-13-9A.998.998 0 0 0 5 3v18a1 1 0 0 0 .536.886zM7 4.909 17.243 12 7 19.091V4.909z"></path>
                            </svg>
                        }
                    >
                        <MenuItem
                        onClick={() => handleLanguageChange("en")}
                            // component={
                            //     <Link
                            //        to="timereport-overview"
                            //        onClick={handleItemClick}
                            //     />
                            // }
                            icon={
                                <img style={{filter: "brightness(1)"}} src={engFlag} alt="" />
                            }
                        >
                            {t("English")}
                        </MenuItem>
                        <MenuItem
                         onClick={() => handleLanguageChange("sv")}
                            icon={
                                <svg id="fi_197564" enable-background="new 0 0 512 512" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg"><circle cx="256" cy="256" fill="#ffda44" r="256"></circle><g fill="#0052b4"><path d="m200.349 222.609h309.484c-16.363-125.607-123.766-222.609-253.833-222.609-19.114 0-37.733 2.113-55.652 6.085v216.524z"></path><path d="m133.565 222.608v-191.481c-70.294 38.354-120.615 108.705-131.398 191.482h131.398z"></path><path d="m133.564 289.391h-131.397c10.783 82.777 61.104 153.128 131.398 191.481z"></path><path d="m200.348 289.392v216.523c17.919 3.972 36.538 6.085 55.652 6.085 130.067 0 237.47-97.002 253.833-222.609h-309.485z"></path></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>
                            }
                        >
                            {t("Swidish")}
                        </MenuItem>
                    </SubMenu>
                </Menu>
                <div className="right-side">
                <figure className="avatar_wrapper flex items-center mt-3">
                <img src={avatar} alt="avatar" width="45px" />
                <figcaption className="flex column-direction">
                  <strong>{`${currentUser?.first_name} ${currentUser?.last_name}`}</strong>
                  {/* <small>HR Manager</small> */}
                </figcaption>
              </figure>
              
            </div>
            </Sidebar>
        </>
    );
};
